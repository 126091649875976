import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { CurrencyPipe } from '@angular/common';
import { SubscriptionFacade } from "../subscription.facade";
import { catchError, switchMap, take, takeUntil } from "rxjs/operators";
import { Subject, of } from "rxjs";
import { AnalyticsService } from "../../../../services/analytics.service";
import { CookiesService } from "../../../../services/cookies.service";
import { AccountsService } from "../../../../services/accounts.service";

@Component({
    selector: "ngx-pro-dialog-summary",
    templateUrl: "./pro-dialog-summary.component.html",
    styleUrls: [ "./pro-dialog-summary.component.scss" ],
    providers: [CurrencyPipe]
})
export class ProDialogSummaryComponent {

    constructor(private subscriptionFacade: SubscriptionFacade,
                private analyticsService: AnalyticsService,
                private cookiesService: CookiesService,
                private accountsService: AccountsService,
                private currencyPipe: CurrencyPipe) { }

    @Input() paymentPeriod: string;
    @Input() totalPrice: number;
    @Output() checkout = new EventEmitter<void>();
    @Output() teamQuantityChange = new EventEmitter<void>();
    @Output() seatsChanged = new EventEmitter<number>();
    @Output() planToggled = new EventEmitter<number>();

    unsubscribe$ = new Subject<void>();
    isCouponApplied$ = this.cookiesService.isCouponApplied$;
    isLoading$ = this.subscriptionFacade.isLoadingInlinePaddle$;
    isEarlyBirds$ = this.accountsService.getIsEarlyBirds$();
    minSeats: number;
    currentSeats: number;
    isAnnualPayment: boolean;
    details: Record<string, string | number> | null = null;
    totalEarlyBirdsAnnualPrice: number | null = null;

    ngOnInit() {
        this.detailsSummary();
        this.setIsAnnualPayment();
        if (this.accountsService.getStaticIsEarlyBirds()) {
            this.initializeSeats();
            this.setTotalEarlyBirdsAnnualPrice();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.paymentPeriod) {
            this.setIsAnnualPayment();

            if(!changes.paymentPeriod.firstChange && this.accountsService.getStaticIsEarlyBirds()) {
                this.planToggled.emit(this.currentSeats);
            }
        }
    }

    detailsSummary() {
        let details = null;
        this.subscriptionFacade.checkoutDetails$.pipe(takeUntil(this.unsubscribe$)).pipe(
            switchMap(res => {
                if (!res) return of(null)
                details = res;
                const { name, quantity } = res.product;
                const { customer } = res.checkout.prices;
                const prices = customer.items[ 0 ].display_price.line_price;
                const { net, net_after_discount, tax_after_discount, gross_after_discount, gross, gross_discount } = prices;
                const period = name.includes('annually') ? 'yearly' : 'monthly';
                return of({
                    name,
                    quantity,
                    net,
                    net_after_discount,
                    tax_after_discount,
                    gross_after_discount,
                    gross,
                    gross_discount,
                    period
                })
            }),
            catchError((err) => {
                this.analyticsService.track('failed to load summary details', {
                    'failure message': err.message,
                    'details': details,
                    'details stringified': JSON.stringify(details),
                })
                this.openCheckoutForm()
                return of(null);
            })
        ).subscribe(details => {
            this.details = details;
            if (this.accountsService.getStaticIsEarlyBirds()) {
                this.setTotalEarlyBirdsAnnualPrice();
            }
        });
    }

    get vat() {
        if (!this.details || !this.details.tax_after_discount) return 'To be calculated';
        return this.currencyPipe.transform(
            this.details.tax_after_discount,
            'USD',
            'symbol'
        );
    }

    initializeSeats() {
        this.accountsService.info(true).pipe(
            switchMap(() => {
                return of(this.subscriptionFacade.getOrgSeats());
            }),
            take(1)
        ).subscribe(seats => {
            this.currentSeats = seats;
            this.minSeats = seats;
            this.seatsChanged.emit(this.currentSeats);
        })
    }

    setIsAnnualPayment() {
        this.isAnnualPayment = this.paymentPeriod === "Annually";
    }

    setTotalEarlyBirdsAnnualPrice() {
        if (this.isAnnualPayment) {
            this.totalEarlyBirdsAnnualPrice = this.totalPrice;
        }
    }

    incrementQuantity() {
        this.currentSeats += 1;
        this.seatsChanged.emit(this.currentSeats);
    }

    decrementQuantity() {
        if (this.currentSeats > this.minSeats) {
            this.currentSeats -= 1;
            this.seatsChanged.emit(this.currentSeats);
        }
    }

    triggerPaddleCheckout() {
        const seats = {
            additionalSeats: 0,
            freeSeats: 0,
            quantity: this.currentSeats
        };

        this.subscriptionFacade.initiatePaddle(seats, this.paymentPeriod);
    }

    openCheckoutForm() {
        this.analyticsService.track('click continue payment');
        this.checkout.emit();
    }


    navigateToTeams() {
        this.teamQuantityChange.emit();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

import { Component } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: 'ngx-presentation-expert-dialog',
  templateUrl: 'presentation-expert-dialog.component.html',
  styleUrls: [ 'presentation-expert-dialog.component.scss' ],
})
export class PresentationExpertDialog { 
  constructor(protected ref: NbDialogRef<PresentationExpertDialog>) { }
  
  logos = [ 'nice', 'meta', 'rtl', 'spot', 'xerox', 'pepsi' ];
  values = [ 'g2', 'accessibility', 'gdpr', 'sso' ];
  isBookSlot: boolean = false;


  close() {
    this.ref.close();
  }

  bookSlot() {
    this.isBookSlot = true;
  }
}
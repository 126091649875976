import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { from } from "rxjs";
import { catchError, mergeMap, map } from "rxjs/operators";

import mixpanel from "mixpanel-browser";
import { AuthService } from "../../../auth.service";
import { CookiesService } from "../../../services/cookies.service";
import { LoginFacade } from "../login.facade";
import { environment } from "../../../../environments/environment";
declare const turnstile: Record<string, Function>;

@Component({
  selector: 'ngx-signup',
  templateUrl: './signup.component.html',
  styleUrls: [ './signup.component.scss' ]
})

export class SignupComponent {

  constructor(private authService: AuthService,
              private loginFacade: LoginFacade,
              private cookiesService: CookiesService,
              private router: Router) {

    const mixpanelSettings: any = {
      persistence: 'localStorage'
    };
    if(!environment.production) mixpanelSettings.debug = true;

    mixpanel.init(environment.mixpanel, mixpanelSettings);
    mixpanel.reset();
  }


  @ViewChild('turnstile', { static: true }) turnstile: ElementRef;
  turnstileURL = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
  isPasswordVisible: boolean = false;
  touched: boolean = false;
  formSubmitError: string = null;
  formLoading: boolean = false;
  token: string = null;
  errorCodeMapping = {
    'auth/missing-password': 'Please enter a password',
    'auth/weak-password': 'Password must be at least 8 characters long',
    'auth/email-already-in-use': 'Email already in use',
    'auth/missing-email': 'Please enter an email',
    'auth/invalid-email': 'Please enter a valid email',
    'internal-error': 'Internal error, please try again',
  }

  formTitle$ = this.loginFacade.formTitle$;
  invitationData$ = this.loginFacade.invitationData$.pipe(map((data) => {
    const { company, team } = data;
    if (!company) return null;
    return {
      company,
      team: `${team} ${Number(team) === 1 ? 'member' : 'members'}`
    }
  }));

  form: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3)
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ])
  });


  ngOnInit() {
    this.loginFacade.parseQueryString();
    this.loginFacade.removeMainLoader();
    this.authService.listenToAuthChanges();
    this.getEmailFromPaddleCheckoutCookie();
    this.identifyUserForMixpanel();
    this.track("view sign up page");
  }

  ngAfterViewInit() {
    this.loadTurnstileURL();
  }

  identifyUserForMixpanel() {
    const wizardPayload = this.cookiesService.getCookie('storydoc_wizard_67ebc42a-0835-47b9-9957-6cd8c3d02ef3');

    if (!wizardPayload || typeof wizardPayload !== 'string') return;

    try {
      const { id } = JSON.parse(wizardPayload);
      if (id) {
        mixpanel.identify(id);
      }
    } catch (err) {
      this.track(`Error parsing wizardPayload cookie: ${err.message}`);
    }
  }

  track(event: string) {
    mixpanel.track(event);
  }

  setFormSubmitError(errorCode: string | null) {
    if (!errorCode) return this.formSubmitError = null;
    this.formSubmitError = this.errorCodeMapping[ errorCode ] || null;
  }

  setFormLoading(isLoading: boolean) {
    this.formLoading = isLoading;
  }


  openLogin() {
    this.router.navigateByUrl('/login');
  }

  singUpWithGoogle() {
    this.authService.signInWithGoogleProvider();
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  formSubmit() {
    this.touched = true;
    if (this.form.invalid) return;
    this.setFormSubmitError(null);
    this.setFormLoading(true);
    const { email, password, name } = this.form.value;
    const createUser$ = this.authService.validateEmail(email, this.token)
      .then(() => this.authService.setFirebaseUserDisplayName(name))
      .then(() => this.authService.createUserWithEmailAndPassword(email, password))
      .then(() => this.track("success sign up page"))

    from(createUser$)
      .pipe(
        catchError((error) => {
          const errorCode = error.code || 'internal-error';
          this.setFormLoading(false);
          this.setFormSubmitError(errorCode);
          throw error;
      }))
      .subscribe();
  }


  loadTurnstileURL() {
    const url = this.turnstileURL;
    const script = document.createElement('script');
    script.src = url;
    script.defer = true;
    document.head.appendChild(script);

    script.onload = () => {
      turnstile.render('#turnstile-container', {
        sitekey: environment.turnstileSiteKey,
        callback: (res: string) => {
          this.token = res;
          setTimeout(() => {
            if (!this.turnstile || !this.turnstile.nativeElement) return;
            this.turnstile.nativeElement.style.display = 'none';
          }, 1500);
        }
      });
    }
  }

  getCookie(name: string) {
    const cookie = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return cookie ? cookie[2] : null;
  };

  getEmailFromPaddleCheckoutCookie() {
    const userData = this.getCookie('sd_paddle_checkout');

    if (!userData || typeof userData !== 'string') return;

    try {
      const { userEmail } = JSON.parse(userData);
      if (!userEmail) return;

      this.form.patchValue({ email: userEmail });
    } catch (err) {
      this.track(`Error parsing Paddle checkout cookie: ${err.message}`);
    }
  }

  ngOnDestroy() {
    this.loginFacade.formTitle$.next('Welcome to Storydoc!');
    this.loginFacade.invitationData$.next({});

    this.authService.unsubscribeFromAuthStateListener();

    const script = document.querySelector(`[src="${this.turnstileURL}"]`);
    if (script) script.remove();
  }

}

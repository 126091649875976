import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { getAiSubtypeFromUrl } from "../../components/wizard/utils";

@Injectable({
  providedIn: "root",
})
  
export class LoginFacade {  

  
  removeMainLoader() {
    const loader = document.getElementById('nb-global-spinner');
    if (loader) loader.remove();
  }
  
  // =========== ORG PROPERTIES ===========
  WIZARD_DATA_KEYS = [ 'website', 'industry', 'testingNumber', 'id' ];
  DISTINCT_ID_COOKIE_KEY = 'storydoc_distinct_id_67ebc42a-0835-47b9-9957-6cd8c3d02ef3';

  getSetupData() {
    const hqTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const payload = {
      hqTimezone,
    };
    const gclid = this.getCookie('storydoc_gclid');
    const cid = this.getCookie('storydoc_cid');
    const landingPage = this.getCookie('storydoc_landing_page');

    const cookiePrefix = 'storydoc_params_';
    document.cookie.split(';').filter(cookie => cookie.indexOf(cookiePrefix) > -1).map(cookie => {
      const [k, v] = cookie.trim().split('=');
      payload[k.replace(cookiePrefix, '')] = v;
    })
    const testingNumber = this.getCookie('storydoc-testing-number');
    const distinctId = this.getCookie(this.DISTINCT_ID_COOKIE_KEY);
    const payloadLandingPage = landingPage === '/' ? '/home' : landingPage;

    payload['gclid'] = gclid;
    payload['cid'] = cid;
    payload[ 'isEditorV4' ] = true;
    payload[ 'landingPage' ] = payloadLandingPage;
    payload[ 'landingPageCategory' ] = this.landingPageCategory(landingPage);
    payload[ 'landingPageSubtype' ] = getAiSubtypeFromUrl(landingPage);
    const invitation = this.verifyInvitation();
    if (invitation) {
      payload[ 'invitation_token' ] = invitation;
      this.deleteCookie('sd-invitation-link-data');
    }

    const websiteWizardData = this.getWebsiteWizardData();
    const websiteWizardDataKeys = Object.keys(websiteWizardData);
    if (websiteWizardDataKeys.length) {
      websiteWizardDataKeys.forEach(key => payload[ key ] = websiteWizardData[ key ]);
    }
    if (testingNumber) {
      payload[ 'testingNumber' ] = Number(testingNumber);
      this.deleteCookie('storydoc-testing-number');
    }

    if (distinctId) {
      payload[ 'id' ] = distinctId;
      this.deleteCookie(this.DISTINCT_ID_COOKIE_KEY);
    }

    return payload;
  }

  getLandingPagePathname(landingPage: string) {
    let pathname = landingPage || ''
 
    const domainToRemove = 'storydoc.com';
    const idx = pathname.indexOf(domainToRemove);
    if (idx !== -1) pathname = pathname.substring(idx + domainToRemove.length);
    return pathname;
  }

landingPageCategory(landingPage: string) {
  const pathname = this.getLandingPagePathname(landingPage);
  const categoryMap = new Map([
    [/^\/blog/, 'Blog'],
    [/-template/, 'Templates'],
    [/-slides/, 'Slides'],
    [/\/proposal-maker/, 'Proposal'],
    [/\/pitch-deck-templates/, 'Pitch Deck'],
    [/\/presentation-maker/, 'Presentation'],
    [/\/blog\/what-is-an-impact-report/, 'Reports'],
    [/\/sign-up/, 'Sign Up'],
    [/\/whitepaper-templates/, 'Whitepaper'],
    [/\/case-study-creator/, 'Case Study'],
    [/(creator|maker|software|tool)/gi, 'Tools']
  ]);

  for (let [key, value] of categoryMap) {
    if (key.test(pathname)) return value;
  }

  return 'General';
}
  verifyInvitation() {
    const hasInvitation = this.getCookie('sd-invitation-link-data');
    if (!hasInvitation) return;
    try {
      const parsed = JSON.parse(hasInvitation);
      const token = parsed[ 'invitation_token' ];
      return token || null;
    } catch (e) {
      this.deleteCookie('sd-invitation-link-data');
      return;
    }
  }

  getWebsiteWizardData() {
    const WIZARD_COOKIE_KEY = 'storydoc_wizard_67ebc42a-0835-47b9-9957-6cd8c3d02ef3';
    const data = this.getCookie(WIZARD_COOKIE_KEY);
    if (!data) return {};
    try {
      const parsed = JSON.parse(data);
      const payload = {};
      this.WIZARD_DATA_KEYS.forEach(key => { if (parsed[ key ]) payload[ key ] = parsed[ key ]; });
      return payload;
    } catch (e) {
      return {};
    }
  }
  // =========== ORG PROPERTIES ===========



  //  =========== WORKSPACE ===========
  invitationData$ = new BehaviorSubject<Record<string, any>>({});
  formTitle$ = new BehaviorSubject<string>('Welcome to Storydoc!');

  parseQueryString() {
    const url = window.location.href;
    const data = this.extractQueryParameters(url);
    if (data.invitation_token) this.setCookie('sd-invitation-link-data', JSON.stringify(data), 7);
    if (data.verification_token) this.setCookie('sd-verification-link-data', JSON.stringify(data), 7);


    const invitation = this.getCookie('sd-invitation-link-data');
    const verification = this.getCookie('sd-verification-link-data');

    if (!invitation && !verification) return;
    
    const parsed = invitation ? this.parseJwt(invitation) : this.parseJwt(verification);
    if (parsed) {
      this.formTitle$.next('Join your team on Storydoc!');
      this.invitationData$.next(parsed);
    }

    if ([ '?verification_token', '?invitation_token' ].includes(url)) {
      const newUrl = url.split('?')[ 0 ];
      window.history.replaceState({}, document.title, newUrl);
    }
  }

  extractQueryParameters(url: string): Record<string, string> {
    var queryString = url.split("?")[1];
    if (!queryString) return {};
    var params = new URLSearchParams(queryString);
    var data = {};
    for (var [key, value] of params as any) {
      data[key] = decodeURIComponent(value.replace(/\+/g, " "));
    }
    return data;
  }

  parseJwt(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  hasWizardData() {
    const hasCookies = !!this.getCookie('storydoc_wizard_67ebc42a-0835-47b9-9957-6cd8c3d02ef3');
    if(!hasCookies) return;
    const title = document.querySelector('section[data-id="{{vars.id}}"] h1.main-title');
    this.formTitle$.next('Your new deck is waiting for you');
  }

  //  =========== WORKSPACE ===========


  setCookie(name: string, value: string|number, days?: number) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie =
      name + "=" + (value || "") + expires + "; path=/;domain=storydoc.com";
  }

  getCookie(name: string) {
    return `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift();
  }

  deleteCookie(name: string, path = '/', domain = 'storydoc.com') {
    if (this.getCookie(name)) {
      document.cookie =
        name +
        "=" +
        (path ? ";path=" + path : "") +
        (domain ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { RouterModule } from '@angular/router';
import { GettingStartComponent } from './components/getting-start-component/getting-start-component.component';
import { UpdateBillingDetailsToastComponent } from './components/update-billing-details-toast/update-billing-details-toast.component';
import { UpdateBillingDetailsSuccessToastComponent } from './components/update-billing-details-success-toast/update-billing-details-success-toast.component';
import { Autocomplete } from '../components/autocomplete/autocomplete.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DebouncedAutocompleteComponent } from '../components/debounced-autocomplete/debounced-autocomplete.component';
import { ProPlanLabelComponent } from '../components/pro-team-plan/pro-plan-label/pro-plan-label.component';
import { GoProButtonComponent } from '../components/pro-team-plan/go-pro-button/go-pro-button.component';
import { ActiveCouponLabelComponent } from './components/active-coupon-label/active-coupon-label.component';
import { ProIconComponent } from '../components/pro-team-plan/pro-icon/pro-icon.component';
import { FirstTimeLoginComponent } from './components/first-time-login/first-time-login.component';
import { SafePipe } from '../@theme/pipes/safe.pipe';
import {SetupExternalPaymentDialog} from '../pages/profile/subscription/setup-external-payment/setup-external-payment.component';


import {
    NbActionsModule,
    NbLayoutModule,
    NbMenuModule,
    NbSearchModule,
    NbSidebarModule,
    NbSpinnerModule,
    NbUserModule,
    NbContextMenuModule,
    NbButtonModule,
    NbSelectModule,
    NbIconModule,
    NbToggleModule,
    NbThemeModule,
    NbTooltipModule,
    NbPopoverModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  UserMenuComponent,
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { UpgradeToProDialogComponent } from '../pages/profile/subscription/upgrade-to-pro-dialog/upgrade-to-pro-dialog.component';
import { UpgradeStarterToProDialogComponent } from '../pages/profile/subscription/upgrade-starter-to-pro-dialog/upgrade-starter-to-pro-dialog.component';
import { SuccessUpdatePlanDialog } from '../pages/profile/subscription/success-update-plan-dialog/success-update-plan-dialog.component';
import { ProDialogSummaryComponent } from '../pages/profile/subscription/pro-dialog-summary/pro-dialog-summary.component';
import { UpgradeToTeamDialogComponent } from '../pages/profile/subscription/upgrade-to-team-dialog/upgrade-to-team-dialog.component';
import { TeamPlanLabelComponent } from '../components/pro-team-plan/team-plan-label/team-plan-label.component';
import { EarlyBirdOfferComponent } from '../components/pro-team-plan/early-bird-offer/early-bird-offer.component';
import { GoTeamButtonComponent } from '../components/pro-team-plan/go-team-button/go-team-button.component';
import { CheckPaymentStatusDialogComponent } from '../components/check-payment-status-dialog/check-payment-status-dialog.component';
import { BlockProUpgradeDialogComponent } from '../pages/profile/subscription/block-pro-upgrade-dialog/block-pro-upgrade-dialog.component';
import { WorkspaceInvitationMessageComponent } from '../components/workspace/workspace-invitation-message/workspace-invitation-message.component';
import { WorkspaceEmailConfirmationComponent } from '../components/workspace/workspace-email-confirmation/workspace-email-confirmation.component';
import { WorkspaceJoinComponent } from '../components/workspace/workspace-join/workspace-join.component';
import { WorkspaceJoinLibraryComponent } from '../components/workspace/workspace-join-library/workspace-join-library.component';
import { SeatsPipe } from '../components/workspace/seats.pipe';
import { WorkspaceSuccessJoinDialogComponent } from '../components/workspace/workspace-success-join/workspace-success-join.component';
import { WorkspaceComponent } from '../components/workspace/workspace/workspace.component';
import { FailedUpgradePlanMessageComponent } from '../pages/profile/subscription/failed-upgrade-plan-message/failed-upgrade-plan-message.components';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbToggleModule,
  NbEvaIconsModule,
  NbTooltipModule,
];
const COMPONENTS = [
  HeaderComponent,
  UserMenuComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  GettingStartComponent,
  UpdateBillingDetailsToastComponent,
  UpdateBillingDetailsSuccessToastComponent,
  Autocomplete,
  DebouncedAutocompleteComponent,
  ProPlanLabelComponent,
  UpgradeToProDialogComponent,
  SetupExternalPaymentDialog,
  UpgradeStarterToProDialogComponent,
  SuccessUpdatePlanDialog,
  TeamPlanLabelComponent,
  UpgradeToTeamDialogComponent,
  FailedUpgradePlanMessageComponent,
  EarlyBirdOfferComponent,
  GoProButtonComponent,
  ProIconComponent,
  GoTeamButtonComponent,
  CheckPaymentStatusDialogComponent,
  BlockProUpgradeDialogComponent,
  WorkspaceInvitationMessageComponent,
  WorkspaceEmailConfirmationComponent,
  WorkspaceJoinComponent,
  WorkspaceJoinLibraryComponent,
  WorkspaceSuccessJoinDialogComponent,
  WorkspaceComponent,
  ProDialogSummaryComponent,
  ActiveCouponLabelComponent,
  FirstTimeLoginComponent
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  SeatsPipe,
  SafePipe
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES, FormsModule, RouterModule, NbPopoverModule, AutocompleteLibModule],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [ ...COMPONENTS, ...PIPES ],
  entryComponents: [
    UpgradeToProDialogComponent,
    SetupExternalPaymentDialog,
    UpgradeStarterToProDialogComponent,
    UpgradeToTeamDialogComponent,
    FailedUpgradePlanMessageComponent,
    CheckPaymentStatusDialogComponent,
    BlockProUpgradeDialogComponent,
    WorkspaceJoinLibraryComponent,
    WorkspaceSuccessJoinDialogComponent,
    SuccessUpdatePlanDialog
  ]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [
        CookieService,
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME ],
        ).providers,
      ],
    };
  }
}
